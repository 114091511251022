import React from 'react'
import loadable from '@loadable/component'
import { Styled } from 'theme-ui'

import PrismCore from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-csharp'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-http'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-cypher'
import 'prismjs/components/prism-powershell'
import 'prismjs/components/prism-dart'

const Prism = loadable(() => import('@theme-ui/prism'))

const CodeBlock = props => (
  // eslint-disable-next-line react/jsx-pascal-case
  <Prism
    {...props}
    Prism={PrismCore}
    fallback={<Styled.pre>{props.children}</Styled.pre>}
  />
)

export default CodeBlock
