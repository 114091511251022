import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentButtons from '@solid-ui-components/ContentButtons'
import { Script } from 'gatsby'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { Button, Link } from 'theme-ui'

const BlogCta = ({ subtitle }) => {
  const text = [
    {
      text: 'Try it for free: 1 app, 2 environments, unlimited feature flags',
      color: 'alpha',
      variant: 'h3'
    },
    {
      text: subtitle ?? 'Live Feature Toggles. No credit card required'
    }
  ]

  const buttons = [
    {
      text: 'Start Toggling',
      type: 'PAGE',
      link: 'https://app.toggly.io/register',
      variant: 'primary',
      width: '100%'
    }
  ]

  return (
    <>
        <ContentContainer variant='cards.paper'>
          <Flex
            sx={{
              flexDirection: [`column`, `row`],
              justifyContent: `space-between`,
              alignItems: `center`,
              textAlign: [`center`, `left`]
            }}
          >
            <Box style={{ flexShrink: 1 }}>
              <ContentText content={text} />
            </Box>
            <Box
              style={{ flexShrink: 0, marginTop: `10px`, marginBottom: `10px` }}
            >
              <ContentButtons content={buttons} />
            </Box>
          </Flex>
        </ContentContainer>
    </>
  )
}

export default BlogCta
